<template>
  <v-btn class="text-none text-body-1 font-weight-medium"
         color="#008081"
         rounded
         text>
    Registrarse
  </v-btn>
</template>

<script>
export default {
  name: "RegisterSignIn",
}
</script>

<style scoped>
</style>
